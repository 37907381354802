import React from "react";
import Layout from "../layout";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Github from "../components/github";

export default ({ uri }) => {
  // const {
  //   file: {
  //     childImageSharp: { fluid },
  //   },
  // } = useStaticQuery(graphql`
  //   query {
  //     file(relativePath: { eq: "image.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 530) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `);
  return (
    <Layout path={uri}>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>Mohamed's Porfolio &#129305;</title>
        <meta
          name="description"
          content="Full Stack Web Developer Portfolio."
        />
        <meta name="robots" content="nofollow" />
      </Helmet>
      <div className="about fade page">
        <div className="text">
          I build full stack applications with web technologies. Most
          comfortable with Javascript development; <b>JAM/MERN</b> stacks. I
          work with <b>Django</b> and <b>LAMP</b> stacks occasionally.
        </div>
        {/* 
        <div className="image-container">
          <Img fluid={fluid} className="img" /> 
        </div>
        */}
      </div>
      <Github />
    </Layout>
  );
};
